import { siteConfig, type SiteName } from './site-config';

export const languages = ['en', 'th', 'id', 'zh'] as const;
export type Language = (typeof languages)[number];
export const isLanguage = (value: string): value is Language =>
  (languages as readonly string[]).includes(value);

export const locales = [
  'en-AU',
  'en-HK',
  'en-ID',
  'en-MY',
  'en-NZ',
  'en-PH',
  'en-SG',
  'en-TH',
  'th-TH',
  'id-ID',
  'zh-HK',
] as const;
export type Locale = (typeof locales)[number];
export const isLocale = (value: string): value is Locale =>
  (locales as readonly string[]).includes(value);

type AppLanguageLocales<Locales> = {
  [Index in keyof Locales]: { name: Locales[Index]; extends: Language };
};

const appLanguageLocales = [
  { name: 'en-AU', extends: 'en' },
  { name: 'en-HK', extends: 'en' },
  { name: 'en-ID', extends: 'en' },
  { name: 'en-MY', extends: 'en' },
  { name: 'en-NZ', extends: 'en' },
  { name: 'en-PH', extends: 'en' },
  { name: 'en-SG', extends: 'en' },
  { name: 'en-TH', extends: 'en' },
  { name: 'th-TH', extends: 'th' },
  { name: 'id-ID', extends: 'id' },
  { name: 'zh-HK', extends: 'zh' },
] satisfies AppLanguageLocales<typeof locales>;

/** A pre-defined sku `languages` config containing all languages that must be supported for a typical SEEK website.
 * This should only be used inside a sku config.
 * @see https://seek-oss.github.io/sku/#/./docs/configuration?id=languages
 */
export const appLanguages = [...languages, ...appLanguageLocales] as const;

export const defaultLanguage = 'en' satisfies Language;

export const getLocale = (
  site: SiteName,
  language: Language,
): Language | Locale => {
  const country = siteConfig[site].classification.country;

  if (!country) {
    return language;
  }

  return `${language}-${country.toUpperCase()}` as Locale;
};

export const getLanguageFromLocale = (
  locale: Language | `${Language}-${string}`,
): Language => locale.slice(0, 2) as Language;
