import { objectKeys } from '@seek/melways-util-functions';

import {
  type Brand,
  type Country,
  type Product,
  siteConfig,
  type SiteName,
} from './site-config';

export const findRootSite = (
  product: Product,
  brand: Brand,
): SiteName | undefined =>
  objectKeys(siteConfig).find((site) => {
    const { classification } = siteConfig[site];

    return (
      classification.product === product &&
      classification.brand === brand &&
      classification.country === null
    );
  });

export const findNonRootSite = (
  product: Product,
  country: Country,
): SiteName | undefined =>
  objectKeys(siteConfig).find((site) => {
    const { classification } = siteConfig[site];

    return (
      classification.product === product && classification.country === country
    );
  });
