/**
 * This section has been added to support the hack of having the homepage for
 * non-English sites have no suffix (e.g. / instead of /th).
 *
 * Refrain from adding new sites to this list. This is a non-preferred workaround.
 *
 * See PR and JIRA ticket for more detail.
 *
 * https://github.com/SEEK-Jobs/melways/pull/571
 * https://myseek.atlassian.net/browse/UPH-401
 */
export const isNonStandardHomepageLanguageSite = (
  site: string,
): site is NonStandardHomepageLanguageSite =>
  site in nonStandardHomepageDefaultLanguages;

export const nonStandardHomepageDefaultLanguages = {
  'candidate-jobsdb-th': 'th',
  'candidate-jobstreet-id': 'id',
} as const;

export type NonStandardHomepageLanguageSite =
  keyof typeof nonStandardHomepageDefaultLanguages;
/* <END_HACKERY> */
