import { siteConfig, type SiteName } from './site-config';

// Removing an alternative name can lead to a breaking change and should only be
// done once all usages across the organisation have been removed.
// If all alternative names are removed this type should be changed to `never`.
export type AlternativeName = 'subDomainRegion' | 'topLevelRegion';

export type Alternatives = Partial<
  Record<
    AlternativeName,
    {
      host: string;
      stagingHost: string;
    }
  >
>;

export const getAlternatives = (site: SiteName): Alternatives => {
  const config = siteConfig[site];

  return 'alternatives' in config ? (config.alternatives ?? {}) : {};
};

export const getAlternativeHosts = (
  site: SiteName,
  staging: boolean,
): string[] =>
  Object.values(getAlternatives(site)).map(({ host, stagingHost }) =>
    staging ? stagingHost : host,
  );

export const getAlternativeHost = (
  site: SiteName,
  staging: boolean,
  name: AlternativeName,
): string | undefined => {
  const { host, stagingHost } = getAlternatives(site)[name] ?? {};

  return staging ? stagingHost : host;
};
