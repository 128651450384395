import { objectKeys } from '@seek/melways-util-functions';

import {
  type Country,
  type CountrySiteName,
  type Product,
  siteConfig,
} from './site-config';
import { getSiteNameFromCountryAndProduct } from './url';

// List of melways relevant zones matching
// https://github.com/SEEK-Jobs/rfc/blob/master/RFC034-Audience-Zone-Identifiers.md#zones
const zoneToCountry = {
  'anz-1': 'au',
  'anz-2': 'nz',
  'asia-1': 'hk',
  'asia-3': 'th',
  'asia-4': 'id',
  'asia-5': 'my',
  'asia-6': 'ph',
  'asia-7': 'sg',
} as const satisfies Record<string, Country>;

export type Zone = keyof typeof zoneToCountry;
export const isZone = (value: string): value is Zone => value in zoneToCountry;

export const getSiteFromZoneAndProduct = (
  zone: Zone,
  product: Product,
): CountrySiteName => {
  const country = zoneToCountry[zone];

  try {
    return getSiteNameFromCountryAndProduct({ country, product });
  } catch (cause) {
    throw new Error(`No site with zone "${zone}" and product "${product}"`, {
      cause,
    });
  }
};

export const getZoneFromSite = (site: CountrySiteName): Zone => {
  const zones = objectKeys(zoneToCountry);
  const { classification } = siteConfig[site];
  const { country } = classification;

  const matchingZone = zones.find((zone) => zoneToCountry[zone] === country);

  if (!matchingZone) {
    throw new Error(`No zone matching site "${site}"`);
  }

  return matchingZone;
};
