import type { Alternatives } from './alternatives';
import type { Language } from './languages';

type AllowableProduct = 'candidate' | 'employer';
type AllowableBrand = 'jobsdb' | 'jobstreet' | 'seek';
type AllowableCountry = 'au' | 'nz' | 'hk' | 'th' | 'sg' | 'id' | 'my' | 'ph';

interface AllowableClassification {
  product: AllowableProduct;
  brand: AllowableBrand;
  country: AllowableCountry | null;
}

interface AllowableSiteConfig {
  host: string;
  stagingHost: string;
  alternatives?: Alternatives;
  languages: Language[];
  classification: AllowableClassification;
}

const createSites = <InputObj extends Record<string, AllowableSiteConfig>>(
  input: InputObj,
) => input;

export const siteConfig = createSites({
  'candidate-seek-root': {
    host: 'www.seek.com',
    stagingHost: 'www.staging.seek.com',
    languages: ['en'],
    classification: {
      product: 'candidate',
      brand: 'seek',
      country: null,
    },
  },
  'candidate-seek-au': {
    host: 'www.seek.com.au',
    stagingHost: 'www.staging.seek.com.au',
    languages: ['en'],
    classification: {
      product: 'candidate',
      brand: 'seek',
      country: 'au',
    },
  },
  'candidate-seek-nz': {
    host: 'www.seek.co.nz',
    stagingHost: 'www.staging.seek.co.nz',
    languages: ['en'],
    classification: {
      product: 'candidate',
      brand: 'seek',
      country: 'nz',
    },
  },
  'candidate-jobsdb-hk': {
    host: 'hk.jobsdb.com',
    stagingHost: 'hk.staging.jobsdb.com',
    languages: ['en', 'zh'],
    classification: {
      product: 'candidate',
      brand: 'jobsdb',
      country: 'hk',
    },
  },
  'candidate-jobsdb-th': {
    host: 'th.jobsdb.com',
    stagingHost: 'th.staging.jobsdb.com',
    languages: ['en', 'th'],
    classification: {
      product: 'candidate',
      brand: 'jobsdb',
      country: 'th',
    },
  },
  'candidate-jobsdb-root': {
    host: 'www.jobsdb.com',
    stagingHost: 'www.staging.jobsdb.com',
    languages: ['en'],
    classification: {
      product: 'candidate',
      brand: 'jobsdb',
      country: null,
    },
  },
  'candidate-jobstreet-sg': {
    host: 'sg.jobstreet.com',
    stagingHost: 'sg.staging.jobstreet.com',
    alternatives: {
      topLevelRegion: {
        host: 'www.jobstreet.com.sg',
        stagingHost: 'www.staging.jobstreet.com.sg',
      },
    },
    languages: ['en'],
    classification: {
      product: 'candidate',
      brand: 'jobstreet',
      country: 'sg',
    },
  },
  'candidate-jobstreet-id': {
    host: 'id.jobstreet.com',
    stagingHost: 'id.staging.jobstreet.com',
    alternatives: {
      topLevelRegion: {
        host: 'www.jobstreet.co.id',
        stagingHost: 'www.staging.jobstreet.co.id',
      },
    },
    languages: ['id', 'en'],
    classification: {
      product: 'candidate',
      brand: 'jobstreet',
      country: 'id',
    },
  },
  'candidate-jobstreet-ph': {
    host: 'ph.jobstreet.com',
    stagingHost: 'ph.staging.jobstreet.com',
    alternatives: {
      topLevelRegion: {
        host: 'www.jobstreet.com.ph',
        stagingHost: 'www.staging.jobstreet.com.ph',
      },
    },
    languages: ['en'],
    classification: {
      product: 'candidate',
      brand: 'jobstreet',
      country: 'ph',
    },
  },
  'candidate-jobstreet-my': {
    host: 'my.jobstreet.com',
    stagingHost: 'my.staging.jobstreet.com',
    alternatives: {
      topLevelRegion: {
        host: 'www.jobstreet.com.my',
        stagingHost: 'www.staging.jobstreet.com.my',
      },
    },
    languages: ['en'],
    classification: {
      product: 'candidate',
      brand: 'jobstreet',
      country: 'my',
    },
  },
  'candidate-jobstreet-root': {
    host: 'www.jobstreet.com',
    stagingHost: 'www.staging.jobstreet.com',
    languages: ['en'],
    classification: {
      product: 'candidate',
      brand: 'jobstreet',
      country: null,
    },
  },
  'employer-seek-au': {
    host: 'talent.seek.com.au',
    stagingHost: 'talent.staging.seek.com.au',
    languages: ['en'],
    classification: {
      product: 'employer',
      brand: 'seek',
      country: 'au',
    },
  },
  'employer-seek-nz': {
    host: 'talent.seek.co.nz',
    stagingHost: 'talent.staging.seek.co.nz',
    languages: ['en'],
    classification: {
      product: 'employer',
      brand: 'seek',
      country: 'nz',
    },
  },
  'employer-seek-hk': {
    host: 'hk.employer.seek.com',
    stagingHost: 'hk.employer.staging.seek.com',
    languages: ['en'],
    classification: {
      product: 'employer',
      brand: 'seek',
      country: 'hk',
    },
  },
  'employer-seek-th': {
    host: 'th.employer.seek.com',
    stagingHost: 'th.employer.staging.seek.com',
    languages: ['en', 'th'],
    classification: {
      product: 'employer',
      brand: 'seek',
      country: 'th',
    },
  },
  'employer-seek-sg': {
    host: 'sg.employer.seek.com',
    stagingHost: 'sg.employer.staging.seek.com',
    languages: ['en'],
    classification: {
      product: 'employer',
      brand: 'seek',
      country: 'sg',
    },
  },
  'employer-seek-id': {
    host: 'id.employer.seek.com',
    stagingHost: 'id.employer.staging.seek.com',
    languages: ['id', 'en'],
    classification: {
      product: 'employer',
      brand: 'seek',
      country: 'id',
    },
  },
  'employer-seek-ph': {
    host: 'ph.employer.seek.com',
    stagingHost: 'ph.employer.staging.seek.com',
    languages: ['en'],
    classification: {
      product: 'employer',
      brand: 'seek',
      country: 'ph',
    },
  },
  'employer-seek-my': {
    host: 'my.employer.seek.com',
    stagingHost: 'my.employer.staging.seek.com',
    languages: ['en'],
    classification: {
      product: 'employer',
      brand: 'seek',
      country: 'my',
    },
  },
});

export type SiteName = keyof typeof siteConfig;
export const isSiteName = (value: string): value is SiteName =>
  value in siteConfig;

export type SiteConfig = (typeof siteConfig)[SiteName];

export type Classification = {
  [Key in keyof SiteConfig['classification']]: SiteConfig['classification'][Key];
};

export type SiteNamesMatchingClassification<T extends Partial<Classification>> =
  {
    [Key in SiteName]: (typeof siteConfig)[Key]['classification'] extends T
      ? Key
      : never;
  }[SiteName];

export type CountrySiteName = SiteNamesMatchingClassification<{
  country: Country;
}>;
export const isCountrySiteName = (value: string): value is CountrySiteName =>
  isSiteName(value) && siteConfig[value].classification.country !== null;

const products = Object.values(siteConfig).map(
  (site) => site.classification.product,
);

export type Product = (typeof products)[number];
export const isProduct = (value: string): value is Product =>
  (products as string[]).includes(value);

const brands = Object.values(siteConfig).map(
  (site) => site.classification.brand,
);

export type Brand = (typeof brands)[number];
export const isBrand = (value: string): value is Brand =>
  (brands as string[]).includes(value);

const countries = Object.values(siteConfig)
  .map((site) => site.classification.country)
  .filter(
    (country): country is Exclude<typeof country, null> => country !== null,
  );

export type Country = (typeof countries)[number];
export const isCountry = (value: string): value is Country =>
  (countries as string[]).includes(value);
