interface JoinPathOptions {
  leading?: boolean;
  trailing?: boolean;
}
export const joinPaths = (segments: string[], options?: JoinPathOptions) => {
  let joinedPath = '';

  for (const segment of segments) {
    if (!segment || segment === '/') {
      continue;
    }

    let newSegment = segment;

    if (newSegment[0] === '/') {
      newSegment = newSegment.slice(1);
    }

    if (newSegment[newSegment.length - 1] === '/') {
      newSegment = newSegment.slice(0, -1);
    }

    joinedPath += `${newSegment}/`;
  }

  if (options?.leading) {
    joinedPath = `/${joinedPath}`;
  }

  if (!options?.trailing) {
    joinedPath = joinedPath.slice(0, -1);
  }

  let doubleSlashIndex = joinedPath.indexOf('//');

  while (doubleSlashIndex !== -1) {
    joinedPath = joinedPath.replace('//', '/');
    doubleSlashIndex = joinedPath.indexOf('//');
  }

  return joinedPath;
};
