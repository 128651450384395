import { joinPaths } from '@seek/melways-util-functions';

import {
  defaultLanguage,
  getLocale,
  type Language,
  type Locale,
} from './languages';
import { siteConfig, type SiteName } from './site-config';

export const getLocalizedRoutes =
  <RouteData extends { route: string }>(baseRoutes: readonly RouteData[]) =>
  (
    site: SiteName,
  ): Array<
    RouteData & { languages: [Language | Locale]; language: Language | Locale }
  > =>
    baseRoutes.flatMap((routeData) => {
      const { languages } = siteConfig[site];

      return languages.map((language) => {
        const locale = getLocale(site, language);
        const route =
          language === defaultLanguage
            ? routeData.route
            : joinPaths([language, routeData.route], { leading: true });

        return {
          ...routeData,
          route,
          languages: [locale],
          language: locale,
        };
      });
    });
