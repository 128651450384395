import type { Brand, Country, Product, SiteName } from './site-config';

export type SiteNames = Record<
  Product,
  Partial<Record<Brand, Partial<Record<Country, SiteName>>>>
>;

export const siteNames = {
  candidate: {
    seek: { au: 'candidate-seek-au', nz: 'candidate-seek-nz' },
    jobsdb: { hk: 'candidate-jobsdb-hk', th: 'candidate-jobsdb-th' },
    jobstreet: {
      sg: 'candidate-jobstreet-sg',
      id: 'candidate-jobstreet-id',
      ph: 'candidate-jobstreet-ph',
      my: 'candidate-jobstreet-my',
    },
  },
  employer: {
    seek: {
      au: 'employer-seek-au',
      nz: 'employer-seek-nz',
      hk: 'employer-seek-hk',
      th: 'employer-seek-th',
      sg: 'employer-seek-sg',
      id: 'employer-seek-id',
      ph: 'employer-seek-ph',
      my: 'employer-seek-my',
    },
  },
} as const satisfies SiteNames;
