import { isNonStandardHomepageLanguageSite } from '@seek/melways-common-types';
import { joinPaths, objectKeys } from '@seek/melways-util-functions';

import {
  type AlternativeName,
  getAlternativeHost,
  getAlternativeHosts,
} from './alternatives';
import {
  defaultLanguage,
  getLanguageFromLocale,
  type Language,
} from './languages';
import {
  type Brand,
  type Country,
  type CountrySiteName,
  type Product,
  siteConfig,
  type SiteName,
} from './site-config';
import { findNonRootSite, findRootSite } from './site-utils';

export const HOMEPAGE_LANGUAGE_OVERRIDE_PATH = '_HOMEPAGE_LANGUAGE_OVERRIDE_';

const isValidLanguageForSite = (site: SiteName, language: Language) => {
  const { languages } = siteConfig[site];

  return (languages as string[]).indexOf(language) !== -1;
};

const assertValidLanguageForSite = (
  site: SiteName,
  language: Language,
  locale: string,
) => {
  if (!isValidLanguageForSite(site, language)) {
    const parsedValueMessage =
      language !== locale ? `, parsed from "${locale}"` : '';

    throw new Error(
      `Site "${site}" doesn't support language "${language}"${parsedValueMessage}`,
    );
  }
};

const inferLanguageForSite = (site: SiteName, currLanguage: Language) =>
  isValidLanguageForSite(site, currLanguage)
    ? currLanguage
    : siteConfig[site].languages[0];

interface GetLocalizedProps {
  language: Language;
  path: string;
  site: SiteName;
}

const getLocalizedPath = ({ language, path, site }: GetLocalizedProps) => {
  /**
   * This section has been added to support the hack of having the homepage for
   * non-English sites have no suffix (e.g. / instead of /th).
   *
   * See PR and JIRA ticket for more detail.
   *
   * https://github.com/SEEK-Jobs/melways/pull/571
   * https://myseek.atlassian.net/browse/UPH-401
   */

  if (path === HOMEPAGE_LANGUAGE_OVERRIDE_PATH) {
    if (isNonStandardHomepageLanguageSite(site)) {
      return language === defaultLanguage ? `/${defaultLanguage}` : '/';
    }

    // For all other sites, treat the HOMEPAGE_LANGUAGE_OVERRIDE_PATH value as
    // if it were the regular root path '/'.
    // eslint-disable-next-line no-param-reassign
    path = '/';
  }
  /** </hackery> */

  if (language === defaultLanguage) {
    return path;
  }

  return joinPaths([language, path], {
    leading: true,
    trailing: path.length > 1 && path.endsWith('/'),
  });
};

interface GetAbsoluteSiteUrlProps {
  site: SiteName;
  path: string;
  staging: boolean;
  alternative?: AlternativeName;
}
const getAbsoluteSiteUrl = ({
  site,
  path,
  staging,
  alternative,
}: GetAbsoluteSiteUrlProps) => {
  const targetHost = getHostName({ site, staging, alternative });

  return `https://${targetHost}${path}`;
};

interface CreateUrlResolverProps {
  language: Language | `${Language}-${string}`;
  site: SiteName;
  staging?: boolean;
  alternative?: AlternativeName;
}

type UrlProps = {
  product?: Product;
  language?: Language | `${Language}-${string}`;
  path: string;
  absolute?: boolean;
} & (
  | {
      country?: Country;
    }
  | {
      country: null;
      brand: Brand;
    }
);

export const createUrlResolver = ({
  site,
  language: currentLanguage,
  staging = false,
  alternative,
}: CreateUrlResolverProps) => {
  const currentClassification = siteConfig[site].classification;

  return ({
    product = currentClassification.product,
    language: requestedLanguage,
    path,
    absolute = false,
    ...rest
  }: UrlProps) => {
    const destinationCountry =
      // Explicit undefined check rather than defaulting with a logical OR
      // as we want to use `rest.country` if it is explicitly set to null
      rest.country === undefined ? currentClassification.country : rest.country;

    if (rest.country === null && !rest.brand) {
      throw new Error('You must provide a brand.');
    }

    const classificationInput: ClassificationInput = {
      product,
      country: destinationCountry,
      // @ts-expect-error Can't infer the type of `rest.brand`, but it doesn't matter as
      // getSiteNameFromClassification validates the input
      brand: rest.brand,
    };

    const destinationSite = getSiteNameFromClassification(classificationInput);

    const language = requestedLanguage
      ? getLanguageFromLocale(requestedLanguage)
      : inferLanguageForSite(
          destinationSite,
          getLanguageFromLocale(currentLanguage),
        );

    assertValidLanguageForSite(
      destinationSite,
      language,
      requestedLanguage || currentLanguage,
    );

    const localizedPath = getLocalizedPath({
      language,
      path,
      site: destinationSite,
    });

    if (!absolute && destinationSite === site) {
      // We are targetting the same site so we can return a relative URL
      return localizedPath;
    }

    return getAbsoluteSiteUrl({
      site: destinationSite,
      path: localizedPath,
      staging,
      alternative,
    });
  };
};
interface RootClassificationInput {
  product: Product;
  brand: Brand;
  country: null;
}
interface NonRootClassificationInput {
  product: Product;
  country: Country;
}
export type ClassificationInput =
  | NonRootClassificationInput
  | RootClassificationInput;

export const getSiteNameFromClassification = ({
  product,
  ...rest
}: ClassificationInput): SiteName => {
  if (!rest.country && !rest.brand) {
    throw new Error(
      'You must provide a brand to get a root site (where country is `null`)',
    );
  }

  const site = rest.country
    ? findNonRootSite(product, rest.country)
    : findRootSite(product, rest.brand);

  if (!site) {
    const debugInfo = Object.entries({
      product,
      country: rest.country,
      brand: rest.country ? undefined : rest.brand,
    })
      .map(([key, value]) => `  - ${key}: ${JSON.stringify(value)}`)
      .join('\n');

    throw new Error(`No matching site for:\n${debugInfo}`);
  }

  return site;
};

interface GetHostNameProps {
  site: SiteName;
  staging: boolean;
  alternative?: AlternativeName;
}

/**
 *
 * @returns the host name (without protocol or port) for the given site and environment
 */
export const getHostName = ({
  site,
  staging,
  alternative,
}: GetHostNameProps): string => {
  const { host, stagingHost } = siteConfig[site];

  const primaryHost = staging ? stagingHost : host;
  const alternativeHost = alternative
    ? getAlternativeHost(site, staging, alternative)
    : undefined;

  return alternativeHost ?? primaryHost;
};

/**
 *
 * @returns the host names (without protocol or port) for the given site and environment
 */
export const getAllHostNames = ({
  site,
  staging,
}: GetHostNameProps): string[] => {
  const { host, stagingHost } = siteConfig[site];

  const primaryHost = staging ? stagingHost : host;
  const alternativeHosts = getAlternativeHosts(site, staging);

  return [primaryHost, ...alternativeHosts];
};

export const getClassificationFromSiteName = (siteName: SiteName) =>
  siteConfig[siteName].classification;

export const getSiteNameFromCountryAndProduct = ({
  country,
  product,
}: Pick<NonRootClassificationInput, 'country' | 'product'>) =>
  getSiteNameFromClassification({ country, product }) as CountrySiteName;

type AbsoluteUrlProps = {
  product: Product;
  country: Country;
  path: string;
  staging?: boolean;
  alternative?: AlternativeName;
} & (
  | {
      localized: false;
      language?: Language | `${Language}-${string}`;
    }
  | {
      language: Language | `${Language}-${string}`;
    }
);

export const createAbsoluteUrl = (props: AbsoluteUrlProps) => {
  const {
    product,
    country,
    language,
    path,
    staging = false,
    alternative,
  } = props;
  const localized = 'localized' in props ? props.localized : true;

  const site = getSiteNameFromCountryAndProduct({ product, country });

  let resolvedPath = path;

  if (localized) {
    if (!language) {
      throw new Error(
        `Missing language on localized route "${path}". Did you mean to set localized to false?`,
      );
    }

    const normalizedLanguage = getLanguageFromLocale(language);

    assertValidLanguageForSite(site, normalizedLanguage, language);

    resolvedPath = getLocalizedPath({
      language: normalizedLanguage,
      path,
      site,
    });
  }

  return getAbsoluteSiteUrl({
    site,
    path: resolvedPath,
    staging,
    alternative,
  });
};

const hostnameToSiteName = Object.fromEntries(
  objectKeys(siteConfig).flatMap((site) =>
    [
      ...getAllHostNames({ site, staging: false }),
      ...getAllHostNames({ site, staging: true }),
    ].map((hostname) => [hostname, site]),
  ),
);

export const getSiteNameFromHostname = (hostname: string): SiteName => {
  if (!(hostname in hostnameToSiteName)) {
    throw new Error(`Unknown hostname: "${hostname}"`);
  }

  return hostnameToSiteName[hostname];
};
